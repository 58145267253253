export interface PolarComplex {
	r: number;
	phi: number;
}

export class Complex {
	re: number;
	im: number;

	constructor(re: number, im: number = 0) {
		this.re = re;
		this.im = im;
	}

	add(c: Complex | number): Complex {
		if (c instanceof Complex)
			return new Complex(this.re + c.re, this.im + c.im);
		else return new Complex(this.re + c, this.im);
	}

	sub(c: Complex | number): Complex {
		if (c instanceof Complex)
			return new Complex(this.re - c.re, this.im - c.im);
		else return new Complex(this.re - c, this.im);
	}

	mult(c: Complex | number): Complex {
		if (c instanceof Complex)
			return new Complex(
				this.re * c.re - this.im * c.im,
				this.im * c.re + this.re * c.im
			);
		else return new Complex(this.re * c, this.im * c);
	}

	div(c: Complex | number): Complex {
		if (c instanceof Complex) {
			const len = c.abs();
			const con = c.conj();
			return this.mult(con).div(len * len);
		} else return new Complex(this.re / c, this.im / c);
	}

	abs(): number {
		return Math.sqrt(this.re * this.re + this.im * this.im);
	}

	conj(): Complex {
		return new Complex(this.re, -this.im);
	}

	arg(): number {
		return Math.atan2(this.im, this.re);
	}

	toPolar(): PolarComplex {
		return {
			r: this.abs(),
			phi: this.arg(),
		};
	}

	static fromPolar(p: PolarComplex): Complex {
		return new Complex(p.r * Math.cos(p.phi), p.r * Math.sin(p.phi));
	}

	static exp(x: number): Complex {
		return new Complex(Math.cos(x), Math.sin(x));
	}
}

import { Complex } from "@/utils/Complex";

let pi: number = Math.PI;

export function FFT(x: Complex[]): Complex[] {
	const N: number = x.length;
	let y: Complex[] = [];
	let logN: number = Math.log2(N);

	if (N == 1) {
		return x;
	}

	if (!Number.isInteger(logN)) {
		let zero: Complex = new Complex(0, 0);
		let missing: number = 2 ** Math.ceil(logN) - N;
		let padding: Complex[] = new Array(missing).fill(zero);
		x = x.concat(padding);
		return FFT(x);
	}

	let xE: Complex[] = [];
	let xO: Complex[] = [];

	for (let n = 0; n < N / 2; n++) {
		xE[n] = x[2 * n];
		xO[n] = x[2 * n + 1];
	}

	let Ek: Complex[] = FFT(xE);
	let Ok: Complex[] = FFT(xO);

	for (let k = 0; k < N / 2; k++) {
		let w: Complex = Complex.exp((-2 * pi * k) / N);
		y[k] = Ek[k].add(Ok[k].mult(w));
		y[k + N / 2] = Ek[k].sub(Ok[k].mult(w));
	}

	return y;
}

export function IFFT(y: Complex[]): number[] {
	const N: number = y.length;
	let x: Complex[] = [];

	for (let k = 0; k < N; k++) {
		y[k] = y[k].conj();
	}

	x = FFT(y);

	for (let k = 0; k < N; k++) {
		x[k] = x[k].conj();
	}

	return x.map((e) => e.re / N);
}

import { Complex, PolarComplex } from "@/utils/Complex";
import { FFT, IFFT } from "@/equalizer-app/math/FourierTransform";
import {
	plot,
	border,
	minmax,
	title,
	xlabel,
	ylabel,
} from "@/equalizer-app/PlotApp";
import { loadPCM, playPCM } from "@/equalizer-app/AudioPlayer";

export enum PlotType {
	Audio = 0,
	FFT = 1,
	Debug = 2,
}

let rate: number = 22050;
let slider: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
let freq_centers: number[] = [64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384];
let FFT_save: Complex[] = [];
let FFT_edit: Complex[] = [];
let delta_save: number[] = [];
let audio: number[] = [];
let mode: PlotType = PlotType.Audio;
let loadedAudio: number[] = [];

export function set_slider(new_slider: number[]) {
	slider = new_slider;
}

export function set_audio(new_audio: number[] | Float32Array, length: number) {
	if (new_audio instanceof Float32Array) {
		loadedAudio = new Array<number>(new_audio.length);
		for (let i = 0; i < new_audio.length; ++i) {
			loadedAudio[i] = new_audio[i];
		}
	} else {
		loadedAudio = new_audio;
	}
	rate = Math.floor(loadedAudio.length / length);
	// Not quite sure about these, but seems to work?
	FFT_save = [];
	FFT_edit = [];
	delta_save = [];
	audio = [];
}

function linspace(start: number, stop: number, length: number): number[] {
	const delta: number = (stop - start) / length;
	let y: number[] = [];
	for (let x = start; x < stop; x += delta) {
		y.push(x);
	}

	return y;
}

function gaussian(x: number[], mu: number, sigma: number): number[] {
	let y: number[] = [];
	for (let i = 0; i < x.length; i += 1) {
		y[i] = Math.exp(-(((x[i] - mu) / sigma) ** 2) / 2);
	}
	return y;
}

function square(x: number[], mu: number): number[] {
	let y: number[] = [];
	for (let i = 0; i < x.length; i += 1) {
		if (x[i] > mu - 1 / 2 && x[i] < mu + 1 / 2) {
			y[i] = 1;
		} else {
			y[i] = 0;
		}
	}
	return y;
}

function scaling_function(frequencies: number[], signal: Complex[]): Complex[] {
	let delta: number[] = new Array(frequencies.length).fill(0);
	let log_freqs: number[] = frequencies.map((e) => Math.log2(e));

	for (let i = 0; i < freq_centers.length; i++) {
		// let gauss: number[] = gaussian(log_freqs, Math.log2(freq_centers[i]), 0.25);
		let gauss: number[] = square(log_freqs, Math.log2(freq_centers[i]));

		for (let j = 0; j < frequencies.length; j++) {
			delta[j] += gauss[j] * (slider[i] / 6);
		}
	}

	delta_save = delta;

	for (let k = 0; k < signal.length; k++) {
		let polar: PolarComplex = signal[k].toPolar();
		polar.r += polar.r * delta[k];
		signal[k] = Complex.fromPolar(polar);
	}

	return signal;
}

function equalizer(real_signal: number[]) {
	let transformed: Complex[] = [];
	let signal: Complex[] = [];

	for (let i = 0; i < real_signal.length; i++) {
		signal[i] = new Complex(real_signal[i], 0);
	}

	if (FFT_save.length == 0) {
		transformed = FFT(signal);
		FFT_save = transformed.slice();
	} else {
		transformed = FFT_save.slice();
	}

	const N: number = transformed.length / 2;
	let frequencies: number[] = linspace(0, rate / 2, N);

	let scaled: Complex[] = scaling_function(frequencies, transformed);

	scaled[N] = scaled[N - 1];
	for (let k = 1; k < N; k++) {
		scaled[2 * N - k] = scaled[k].conj();
	}

	FFT_edit = scaled;

	let equalized_signal: number[] = IFFT(scaled);

	return equalized_signal.slice(0, signal.length);
}

export function play_audio() {
	playPCM(audio, rate, 1);
}

export function update_audio() {
	if (loadedAudio.length == 0) {
		set_audio(loadPCM("soundList.csv"), 3);
	}

	audio = equalizer(loadedAudio.slice());

	update_plot();
}

export function update_plot() {
	let y: number[] = [];
	let xrange: number[] = [];
	let yrange: number[] = [];

	switch (mode) {
		case PlotType.FFT:
			y = FFT_edit.slice(0, FFT_edit.length / 2).map((e) => e.abs());
			xrange = [0, rate / 2];
			yrange = minmax(y);
			plot(y, yrange, xrange, true);
			border(xrange, yrange, true, true);
			title("Frequency spectrum");
			xlabel("Frequency [Hz]");
			ylabel("Volume [dB]");
			break;
		case PlotType.Debug:
			y = delta_save;
			xrange = [0, rate / 2];
			yrange = [-1, 1];
			plot(y, yrange, xrange, true);
			border(xrange, yrange, false, true);
			title("???");
			xlabel("Frequency [Hz]");
			break;
		default:
			y = audio;
			xrange = [0, y.length / rate];
			yrange = minmax(y);
			plot(y, yrange, xrange, false);
			border(xrange, yrange, true, false);
			title("Soundwave");
			xlabel("Time [s]");
			ylabel("Volume [dB]");
	}
}

export function plot_switch(m: PlotType) {
	mode = m;
	update_plot();
}

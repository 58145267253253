import { delay } from "@/utils/delay";

export default class AudioRecorder {
	private mediaStream: MediaStream | null;
	private audioContext: AudioContext;

	constructor(ctx?: AudioContext) {
		this.mediaStream = null;
		if (!ctx) {
			this.audioContext = new AudioContext();
		} else {
			this.audioContext = ctx;
		}
	}

	/**
	 * Ask user for microphone permissions. Resolves to a boolean which contains
	 * if the user agreed to give microphone permissions
	 */
	async getPermission(): Promise<boolean> {
		if (!navigator.mediaDevices.getUserMedia) {
			return false;
		}
		let ms: MediaStream;
		try {
			ms = await navigator.mediaDevices.getUserMedia({ audio: true });
		} catch (e: unknown) {
			return false;
		}
		this.mediaStream = ms;
		// Mute the microphone until we need it
		for (const track of this.mediaStream.getAudioTracks()) {
			track.enabled = false;
		}
		return true;
	}

	/**
	 * Starts the recording, and stores recorded data in an audio buffer (i.e. an array)
	 * This function may only be used once per instance of the class, create a new one if
	 * you need multiple recordings.
	 * @param length How many seconds of audio to record
	 * @returns Resolves to a Float32Array of PCM data
	 */
	async record(length: number = 10): Promise<Float32Array> {
		if (!this.mediaStream) {
			throw new MediaError();
		}
		await this.audioContext.audioWorklet.addModule("ScriptProcessorWorklet.js");
		// Enable recording again
		for (const track of this.mediaStream.getAudioTracks()) {
			track.enabled = true;
		}
		const source = this.audioContext.createMediaStreamSource(this.mediaStream);
		let pcmData = new Float32Array();
		const proc = new AudioWorkletNode(this.audioContext, "processor-node");
		proc.port.onmessage = (ev) => {
			if (!ev.data) return;
			const data = ev.data[0];
			const temp = new Float32Array(pcmData.length + data.length);
			temp.set(pcmData);
			temp.set(data, pcmData.length);
			pcmData = temp;
		};
		source.connect(proc);
		await delay(length * 1000 - 0.01); // Assume the other code here takes some time
		proc.port.postMessage("stop");
		for (const track of this.mediaStream.getAudioTracks()) {
			track.stop();
		}
		return pcmData;
	}
}

import * as PIXI from "pixi.js";

export interface CircleSpec {
	r: number;
	angleSpeed: number;
	initialAngle: number;
}

export default class RotatingCircle {
	private r: number;
	private angleSpeed: number;
	private child: RotatingCircle | null;
	gfx: PIXI.Graphics;
	drawCallback?: (x: number, y: number) => void;

	/**
	 * Creates a new rotating circle
	 * @param r Radius of the circle
	 * @param angleSpeed Rotation speed in radians/second
	 * @param initialAngle Initial angle in radians
	 * @param child Circle attached at the end of the line.
	 * If child is null, it is assumed that this is the drawing circle.
	 */
	constructor(c: CircleSpec, child?: RotatingCircle) {
		this.r = c.r;
		this.angleSpeed = c.angleSpeed;
		this.child = child ? child : null;

		this.gfx = new PIXI.Graphics();
		this.gfx.lineStyle({ color: 0x000000, width: 1, alpha: 0.6 });
		this.gfx.drawCircle(0, 0, this.r);
		this.gfx.lineTo(this.r, 0);
		this.gfx.rotation = c.initialAngle;
	}

	update(delta: number) {
		// Delta is specified in milliseconds, while anglespeed is in radians/seconds
		this.gfx.rotation += (this.angleSpeed * delta) / 1000;
		// If this is not the inner circle, propagate the update
		if (this.child) {
			this.child.update(delta);
		}
	}

	setPos(x: number, y: number) {
		this.gfx.x = x;
		this.gfx.y = y;
		if (this.child) {
			// If this circle has a child, set it's position to where the
			// radius line is pointing (so it looks like the circles are linked together)
			this.child.setPos(
				this.gfx.x + this.r * Math.cos(this.gfx.rotation),
				this.gfx.y + this.r * Math.sin(this.gfx.rotation)
			);
		} else if (this.drawCallback) {
			// If this circle does not have a child, the drawCallback is used to know where
			// to make the drawing
			const pos = this.gfx.getGlobalPosition();
			this.drawCallback(
				pos.x + this.r * Math.cos(this.gfx.rotation),
				pos.y + this.r * Math.sin(this.gfx.rotation)
			);
		}
	}
}

import { delay } from "@/utils/delay";

/**
 * Plays a PCM-encoded audio piece
 * @param amplitudes PCM audio in the form of an array of amplitudes at sample point
 * @param samplerate Sample rate for the provided audio
 * @param gain (optional) volume boost value
 * @param inputMin (optional) Minimum possible value of amplitudes. Defaults to smallest provided value
 * @param inputMax (optional) Maximum possible value of amplitudes. Defaults to largest provided value
 */
export async function playPCM(
	amplitudes: number[] | Float32Array,
	samplerate: number,
	gain = 1,
	inputMin = 0,
	inputMax = 0,
	ctx?: AudioContext
): Promise<void> {
	if (!ctx) {
		ctx = new AudioContext();
	}
	const gainNode = ctx.createGain();
	gainNode.gain.value = gain;
	gainNode.connect(ctx.destination);
	if (inputMin === inputMax) {
		// TypeScript moment
		if (amplitudes instanceof Float32Array) {
			inputMin = amplitudes.reduce((c: number, e: number) => Math.min(c, e));
			inputMax = amplitudes.reduce((c: number, e: number) => Math.max(c, e));
		} else {
			inputMin = amplitudes.reduce((c: number, e: number) => Math.min(c, e));
			inputMax = amplitudes.reduce((c: number, e: number) => Math.max(c, e));
		}
	}
	// Scales all amplitudes to [-1, 1]
	amplitudes = amplitudes.map(
		(e: number) => ((e - inputMin) * 2) / (inputMax - inputMin) - 1
	);
	const bufferSource = ctx.createBufferSource();
	const buffer = ctx.createBuffer(1, amplitudes.length, samplerate);
	let audioData = buffer.getChannelData(0);
	for (let i = 0; i < amplitudes.length; ++i) {
		audioData[i] = amplitudes[i];
	}
	bufferSource.buffer = buffer;
	bufferSource.connect(gainNode);
	bufferSource.start();
	await delay((1000 * amplitudes.length) / samplerate);
	return;
}

/**
 * Parses a csv file generated from a sound file. Must be located in the assets directory
 * @param filename Name of .csv file placed in the assets directory
 * @returns Array of amplitudes
 */
export function loadPCM(filename: string): number[] {
	const soundList = require(`@/assets/${filename}`).default as string;
	let pcmSound: number[] = [];
	for (let line of soundList.split("\n")) {
		const parsed = parseFloat(line.trim());
		if (isNaN(parsed)) {
			continue;
		}
		pcmSound.push(parsed);
	}
	return pcmSound;
}

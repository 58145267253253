import * as PIXI from "pixi.js";

let started = false;

export const app = new PIXI.Application({
	backgroundColor: 0xfbc6a4,
	width: 1000,
	height: 400,
});

let pencil = new PIXI.Graphics();
let plot_style = { color: 0x000000, width: 1 };
let border_style = { color: 0x000000, width: 1 };
let h = app.screen.height;
let w = app.screen.width;

export function start() {
	if (started) return;

	started = true;

	//let pencil = new PIXI.Graphics();
	app.stage.addChild(pencil);
}

let plt_xmargin = [0.1, 0.05];
let plt_ymargin = [0.15, 0.15];
let brd_xmargin = [0.08, 0.04];
let brd_ymargin = [0.12, 0.12];

export function plot(
	y: number[],
	yrange = [0, 1],
	xrange = [0, 1],
	log = false
) {
	pencil.clear();
	pencil.removeChildren();
	pencil.lineStyle(plot_style);

	let hmin = h * (1 - plt_ymargin[0]);
	let hmax = h * plt_ymargin[1];
	let dh = hmax - hmin;
	let wmin = w * plt_xmargin[0];
	let wmax = w * (1 - plt_xmargin[1]);
	let dw = wmax - wmin;

	let dy = yrange[1] - yrange[0];

	let ynorm = (y[0] - yrange[0]) / dy;
	let xnorm = 0;

	let posX = wmin + xnorm * dw;
	let posY = hmin + ynorm * dh;

	pencil.moveTo(posX, posY);

	for (let i = 1; i < y.length; i++) {
		let ynorm = (y[i] - yrange[0]) / dy;
		let xnorm = i / y.length;

		if (log) {
			let x = xrange[0] + xnorm * (xrange[1] - xrange[0]);
			xnorm =
				Math.log((1 + x) / (1 + xrange[0])) /
				Math.log((1 + xrange[1]) / (1 + xrange[0]));
		}

		let posX = wmin + xnorm * dw;
		let posY = hmin + ynorm * dh;
		pencil.lineTo(posX, posY);
		pencil.moveTo(posX, posY);
	}
}

export function minmax(y: number[]) {
	let min = Infinity;
	let max = -Infinity;

	for (let i = 0; i < y.length; i++) {
		let yi = y[i];
		if (yi < min) min = yi;
		else if (yi > max) max = yi;
	}

	return [min, max];
}

export function border(
	xrange = [0, 1],
	yrange = [-1, 1],
	dB = true,
	log = false,
	xcount = 12,
	ycount = 10
) {
	pencil.lineStyle(border_style);

	/* Outer height of border, assumed to be greater than plot's height
		ymargin <= ymargin2
	*/
	let hmin = h * (1 - brd_ymargin[0]);
	let hmax = h * brd_ymargin[1];
	let dh = hmax - hmin;

	// Inner height of border, assumed to be same as plot's height
	let hmin2 = h * (1 - plt_ymargin[0]);
	let hmax2 = h * plt_ymargin[1];
	let dh2 = hmax2 - hmin2;

	// Outer width of border, assumed to be greater than plot's width
	let wmin = w * brd_xmargin[0];
	let wmax = w * (1 - brd_xmargin[1]);
	let dw = wmax - wmin;

	// Inner width, assumed to be same as plot's widths
	let wmin2 = w * plt_xmargin[0];
	let wmax2 = w * (1 - plt_xmargin[1]);
	let dw2 = wmax2 - wmin2;

	// Draw box
	pencil.moveTo(wmin, hmin);
	pencil.lineTo(wmin, hmax);
	pencil.moveTo(wmin, hmax);
	pencil.lineTo(wmax, hmax);
	pencil.moveTo(wmax, hmax);
	pencil.lineTo(wmax, hmin);
	pencil.moveTo(wmax, hmin);
	pencil.lineTo(wmin, hmin);

	// Draw labels on x-axis
	for (let i = 0; i <= xcount; i++) {
		// Draw a small vertical line
		pencil.moveTo(wmin2 + (i / xcount) * dw2, hmin);
		pencil.lineTo(wmin2 + (i / xcount) * dw2, hmin + 10);

		// Draw text label of number
		let num = xrange[0] + (i / xcount) * (xrange[1] - xrange[0]);

		if (log) {
			num =
				(1 + xrange[0]) *
					Math.pow((xrange[1] + 1) / (xrange[0] + 1), i / xcount) -
				1;
		}

		let text = new PIXI.Text(num.toFixed(2), {
			fontFamily: "Arial",
			fontSize: 12,
			fill: 0x000000,
			align: "center",
		});

		text.x = wmin2 + (i / xcount) * dw2 - 5;
		text.y = hmin + 10;
		pencil.addChild(text);
	}

	// Draw labels on y-axis
	for (let i = 0; i <= ycount; i++) {
		pencil.moveTo(wmin, hmin2 + (i / ycount) * dh2);
		pencil.lineTo(wmin - 10, hmin2 + (i / ycount) * dh2);

		let num = yrange[0] + (i / ycount) * (yrange[1] - yrange[0]);
		if (dB) {
			num = Math.sign(num) * 10 * Math.log10(1 + Math.abs(num));
		}

		let text = new PIXI.Text(num.toFixed(2), {
			fontFamily: "Arial",
			fontSize: 12,
			fill: 0x000000,
			align: "center",
		});
		text.x = wmin - 40;
		text.y = hmin2 + (i / ycount) * dh2 - 10;
		pencil.addChild(text);
	}
}

export function title(title: string) {
	let xpos = w * 0.5;
	let ypos = (h * brd_ymargin[1]) / 2;
	let text = new PIXI.Text(title, {
		fontFamily: "Arial",
		fontSize: 14,
		fill: 0x000000,
		align: "center",
	});
	text.x = xpos;
	text.y = ypos;
	pencil.addChild(text);
}

export function xlabel(label: string) {
	let xpos = w * 0.5 * (1 - brd_xmargin[1] + brd_xmargin[0]);
	let ypos = h * (1 - brd_ymargin[0] / 2);
	let text = new PIXI.Text(label, {
		fontFamily: "Arial",
		fontSize: 14,
		fill: 0x000000,
		align: "center",
	});
	text.x = xpos;
	text.y = ypos;
	pencil.addChild(text);
}

export function ylabel(label: string) {
	let xpos = (w * brd_xmargin[0]) / 4;
	let ypos = h * 0.5 * (1 - brd_ymargin[0] + brd_ymargin[1]);
	let text = new PIXI.Text(label, {
		fontFamily: "Arial",
		fontSize: 14,
		fill: 0x000000,
		align: "center",
	});
	text.x = xpos;
	text.y = ypos;
	text.rotation = -Math.PI / 2;
	pencil.addChild(text);
}





































































































import { Component, Vue, Watch } from "vue-property-decorator";
import {
	update_audio,
	play_audio,
	set_audio,
	plot_switch,
} from "@/equalizer-app/EqualizerApp";
import { app, start } from "@/equalizer-app/PlotApp";
import * as EqualizerApp from "@/equalizer-app/EqualizerApp";
import RecordingDialog from "@/components/RecordingDialog.vue";
import { mdiRecord, mdiPlay } from "@mdi/js";
import { delay } from "@/utils/delay";

@Component({
	components: { RecordingDialog },
})
export default class Equalizer extends Vue {
	s = [64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384]; //[6,7,8,9,10,11,12,13,14]
	slider = [0, 0, 0, 0, 0, 0, 0, 0, 0];
	recordingDialogOpen = false;
	icons = { mdiRecord, mdiPlay };
	selectedPlotType = EqualizerApp.PlotType.Audio;
	plotTypes = [
		{ name: "Audio Wave", mode: EqualizerApp.PlotType.Audio },
		{ name: "Frequency Spectrum", mode: EqualizerApp.PlotType.FFT },
		// Comment out this line to disable debug plot
		// { name: "Debug", mode: EqualizerApp.PlotType.Debug },
	];

	mounted() {
		document.getElementById("equalizer-visualizer")?.appendChild(app.view);
		start();
		update_audio();
	}

	@Watch("slider")
	async updateSlider() {
		const prevSlider = JSON.stringify(this.slider);
		await delay(1000);

		if (prevSlider === JSON.stringify(this.slider)) {
			EqualizerApp.set_slider(
				this.slider.map((e) => ((e + 100) * 12) / 200 - 6)
			);
			update_audio();
		}
	}

	unmounted() {
		document.getElementById("equalizer-visualizer")?.removeChild(app.view);
	}

	play() {
		play_audio();
	}

	switch_plot() {
		plot_switch(this.selectedPlotType);
	}

	set_eq_audio(a: Float32Array | number[]) {
		for (let i = 0; i < this.slider.length; ++i) this.slider[i] = 0;
		set_audio(a, RecordingDialog.SECONDS_TO_RECORD);
		update_audio();
	}
}

import { Complex } from "@/utils/Complex";
import { CircleSpec } from "../objects/RotatingCircle";

function line_int(
	z_1: Complex,
	z_2: Complex,
	t_1: number,
	t_2: number,
	n: number
): Complex {
	let del_t = new Complex(t_2 - t_1);
	let delta_z = z_2.sub(z_1);
	if (n === 0) {
		let t1 = z_1.mult(del_t);
		let t2 = del_t.mult(0.5).mult(delta_z.div(del_t).mult(del_t));
		return t1.add(t2);
	} else {
		let a = new Complex(0, -2 * Math.PI * n);
		let adelt = a.mult(del_t);
		let exp_at = Complex.exp(adelt.im);
		let int1 = z_1.div(a).mult(exp_at.sub(1));
		let int2 = delta_z
			.div(del_t)
			.mult(exp_at.mult(adelt.sub(1)).add(1))
			.div(a.mult(a));
		return Complex.exp(a.mult(t_1).im).mult(int1.add(int2));
	}
}

function one_coef(z_list: Complex[], n: number, del_t: number): Complex {
	let coef = new Complex(0);
	for (let i = 1; i < z_list.length; i++) {
		coef = coef.add(
			line_int(z_list[i - 1], z_list[i], i * del_t, (i + 1) * del_t, n)
		);
	}
	return coef;
}

function fourier_coeff(z_list: Complex[], num_terms: number): Complex[] {
	let del_t = 1 / (z_list.length - 1);
	let c_arr = [];
	for (let i = 0; i < num_terms; i++) {
		c_arr.push(one_coef(z_list, i - Math.floor(num_terms / 2), del_t));
	}
	return c_arr;
}

export function make_inits(
	re_list: number[],
	im_list: number[],
	num_terms: number
): CircleSpec[] {
	let z_list = [];
	for (let i = 0; i < re_list.length; i++) {
		z_list.push(new Complex(re_list[i], im_list[i]));
	}
	num_terms = 2 * num_terms + 1;
	let c_arr = fourier_coeff(z_list, num_terms);
	let inits = new Array<CircleSpec>();
	for (let i = 0; i < num_terms; i++) {
		let C = c_arr[i];
		inits.push({
			r: C.abs() as unknown as number,
			angleSpeed: 2 * Math.PI * (i - Math.floor(num_terms / 2)),
			initialAngle: C.arg(),
		});
	}
	return inits;
}





































































































import { Component, Vue, Watch } from "vue-property-decorator";
import * as CircleApp from "@/drawing-app/CircleApp";
import * as DrawingApp from "@/drawing-app/DrawingApp";
import { delay } from "@/utils/delay";

const WARN_TRESHOLD = 25;

@Component
export default class Drawing extends Vue {
	circleCount = 25;
	showUglyAlert = false;

	mounted() {
		document.getElementById("drawingApp")?.appendChild(DrawingApp.app.view);
		DrawingApp.start();
		document.getElementById("circleApp")?.appendChild(CircleApp.app.view);
		CircleApp.start();
		DrawingApp.onDrawFinished(this.onDrawFinished);
	}

	resetAll() {
		DrawingApp.reset();
		CircleApp.reset();
	}

	@Watch("circleCount")
	async changeCircleCount() {
		const prevCircleCount = this.circleCount;
		await delay(1000);
		// If the user has stopped dragging the slider around
		if (prevCircleCount === this.circleCount) {
			CircleApp.setCirlceCount(prevCircleCount);
		}
	}

	onDrawFinished() {
		const firstPoint = DrawingApp.getPoints()[0];
		const lastPoint = DrawingApp.getPoints()[DrawingApp.getPoints().length - 1];
		const dist = Math.sqrt(
			Math.pow(firstPoint[0] - lastPoint[0], 2) +
				Math.pow(firstPoint[1] - lastPoint[1], 2)
		);
		this.showUglyAlert = dist > WARN_TRESHOLD;
		CircleApp.reset();
	}
}

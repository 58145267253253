import * as PIXI from "pixi.js";
import RotatingCircle from "./objects/RotatingCircle";
import { make_inits } from "./math/fourier-sum";

export interface CircleParams {
	circles?: number;
	points?: number[][];
}

export default class CircleState {
	container: PIXI.Container;
	outerCircle: RotatingCircle;
	pencil: PIXI.Graphics;
	prevDrawX: number = 0;
	prevDrawY: number = 0;

	constructor(params: CircleParams) {
		this.container = new PIXI.Container();

		// First, set up the points to use as figure
		let re_list: number[] = [];
		let im_list: number[] = [];
		if (!params.points) {
			// Default to drawing a large E
			re_list = [30, 350, 350, 110, 110, 270, 270, 110, 110, 350, 350, 30, 30];
			im_list = [30, 30, 110, 110, 190, 190, 270, 270, 350, 350, 430, 430, 30];
		} else {
			// points[][] takes the form [[x0, y0], [x1, y1] ...],
			// Convert it to [x0, x1 ...] and [y0, y1 ...]
			re_list = params.points.map((e) => e[0]);
			im_list = params.points.map((e) => e[1]);
		}

		// Default to generating 25 circles
		if (!params.circles) params.circles = 25;
		// Calculate params for the circles
		let inits = make_inits(re_list, im_list, (params.circles - 1) / 2);
		// Sort the circles by rotation speed, descending
		inits.sort((a, b) => Math.abs(b.angleSpeed) - Math.abs(a.angleSpeed));

		// Generate the inner circle, the one that draws, and give it a pencil
		let prevCircle = new RotatingCircle(inits[0]);
		this.container.addChild(prevCircle.gfx);
		this.pencil = new PIXI.Graphics();
		this.container.addChild(this.pencil);
		prevCircle.drawCallback = (x, y) => {
			this.pencil.moveTo(this.prevDrawX, this.prevDrawY);
			this.pencil.lineTo(x, y);
			this.prevDrawX = x;
			this.prevDrawY = y;
			// If this was specified before drawing any points, there would be a line from (0, 0)
			// to where the drawing starts
			this.pencil.lineStyle({ color: 0x000000, width: 4 });
		};
		// Now that the inner circle has been generated, generate
		for (let i = 1; i < inits.length; ++i) {
			const newCircle = new RotatingCircle(inits[i], prevCircle);
			this.container.addChild(newCircle.gfx);
			prevCircle = newCircle;
		}
		// The final circle generated, the outer one, needs to be accessible later in order
		// to call its update() function
		this.outerCircle = prevCircle;
	}

	update(delta: number) {
		// Each circle will call the update function of it's direct child, which in the
		// end makes all circles update
		this.outerCircle.update(delta);
		this.outerCircle.setPos(0, 0);
	}

	resetPencil() {
		this.pencil.clear();
	}
}














































































import { Component, Prop, Vue } from "vue-property-decorator";
import { mdiPlay, mdiStop, mdiRecord } from "@mdi/js";
import AudioRecorder from "@/equalizer-app/AudioRecorder";
import { playPCM } from "@/equalizer-app/AudioPlayer";

@Component
export default class RecordingDialog extends Vue {
	static SECONDS_TO_RECORD = 5;
	recordingSeconds = RecordingDialog.SECONDS_TO_RECORD;
	@Prop(Boolean) value!: boolean;
	waitingForPermission = false;
	recording = false;
	icons = { mdiPlay, mdiStop, mdiRecord };
	showError = false;
	recorder: AudioRecorder | null = null;
	recordedAudio: Float32Array | null = null;
	audioContext: AudioContext | null = null;
	playingAudio = false;

	async startRecording() {
		if (!this.audioContext) {
			this.audioContext = new AudioContext();
		}
		this.waitingForPermission = true;
		this.recorder = new AudioRecorder(this.audioContext);
		if (!(await this.recorder.getPermission())) {
			this.showError = true;
			this.waitingForPermission = false;
			return;
		}
		this.waitingForPermission = false;
		this.recording = true;
		this.recordedAudio = await this.recorder.record(
			RecordingDialog.SECONDS_TO_RECORD
		);
		this.recording = false;
	}

	async playRecorded() {
		if (!this.recordedAudio) return;
		this.playingAudio = true;
		await playPCM(
			this.recordedAudio,
			Math.floor(this.recordedAudio.length / RecordingDialog.SECONDS_TO_RECORD),
			1,
			1,
			1,
			this.audioContext!
		);
		this.playingAudio = false;
	}

	async finishRecording() {
		if (this.recordedAudio == null) return;
		this.$emit("recorded", this.recordedAudio);
		// Arcane way to set a boolean to false
		this.$emit("input", false);
	}

	abortRecording() {
		// Arcane way to set a boolean to false
		this.$emit("input", false);
	}

	get audioAvailable() {
		return this.recordedAudio != null;
	}
}

import * as PIXI from "pixi.js";
import CircleState from "@/drawing-app/CircleState";
import { getPoints } from "@/drawing-app/DrawingApp";

let started = false;
let circleCount = 25;

export let app = new PIXI.Application({
	backgroundColor: 0xfbc6a4,
	width: 480,
	height: 480,
});

export function start() {
	if (started) return;
	started = true;
	const points = getPoints();

	const cs = new CircleState({
		points: points.length === 0 ? undefined : points,
		circles: circleCount,
	});
	app.stage.addChild(cs.container);
	let elapsed = 0;
	app.ticker.add(() => {
		// Complete drawing in 15 seconds
		cs.update(app.ticker.deltaMS / 15);
		elapsed += app.ticker.deltaMS / 1000;
		if (elapsed > 15) {
			elapsed = 0;
			cs.resetPencil();
		}
	});
}

export function reset(): void {
	app.stage.removeChildren();
	started = false;
	start();
}

export function setCirlceCount(n: number): void {
	circleCount = n;
	reset();
}

import * as PIXI from "pixi.js";

let started = false;
let lastPoints: number[][] = [];
let drawFinished: () => void = () => {};

export const app = new PIXI.Application({
	backgroundColor: 0xfbc6a4,
	width: 480,
	height: 480,
});

export function start() {
	if (started) return;
	started = true;

	let points: number[][] = [];

	app.stage.interactive = true;
	let dragging = false;
	app.stage.on("pointerdown", pointerDown);
	app.stage.on("pointerup", pointerUp);
	app.stage.on("pointermove", pointerMove);
	let pencil = new PIXI.Graphics();
	app.stage.addChild(pencil);

	let prevX = 0;
	let prevY = 0;

	const graphics = new PIXI.Graphics();

	// Rectangle
	graphics.beginFill(0xfbc6a4);
	graphics.drawRect(0, 0, 480, 480);
	graphics.endFill();

	app.stage.addChild(graphics);

	function pointerDown(event: any) {
		dragging = true;
		pointerMove(event);
	}

	function pointerMove(event: any) {
		if (dragging) {
			let x = event.data.global.x;
			let y = event.data.global.y;
			points.push([x, y]);

			graphics.moveTo(prevX, prevY);
			graphics.lineTo(x, y);
			prevX = x;
			prevY = y;
			graphics.lineStyle({ color: 0x000000, width: 4 });
		}
	}

	function pointerUp(_: any) {
		dragging = false;
		lastPoints = points;
		drawFinished();
	}
}

export function getPoints(): number[][] {
	return lastPoints;
}

export function onDrawFinished(cb: () => void): void {
	drawFinished = cb;
}

export function reset() {
	app.stage.removeChildren();
	lastPoints = [];
	started = false;
	start();
}
